<template>
  <div>

    <HeaderMain />

    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form form w-container">
        <div class="from-login w-form">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="66668e6af010f14082efc493" data-wf-element-id="d73203a7-f081-4301-5c74-acb811806f90">
            <v-form ref="form_reg_check" v-model="valid_log_check" lazy-validation>
              <div class="tittle-form phone-sms" style="margin-bottom: 20px;">
                <h1 class="heading-form">Подтверждение номера</h1>
                <div class="text-classic-link">На номер <span class="bold-phone">{{ phone }}</span> отправлено SMS с кодом</div>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Phone-SMS" class="input-label">Введите код из SMS</label>
                    <v-text-field
                        outlined
                        type="text"
                        @input="errorReset"
                        :rules="phoneCodeRules"
                        v-model="code"
                        inputmode="numeric"
                        v-mask="'####'"
                        :disabled="isCodeSend || timerExpire || disableCode"
                        @keydown.enter="handleEnter"
                    ></v-text-field>

                    <div v-if="!timerExpire && !isCodeSend" class="w-layout-hflex time-inpur-form"><img src="../../../../../public/images/Outline_1.svg" loading="lazy" alt="">
                      <div class="time-phone-sms"><span class="bold-time-sms">{{ formattedTime }}</span> - Отправить код повторно</div>
                    </div>
                    <div v-if="timerExpire" class="w-layout-hflex time-inpur-form"><img src="../../../../../public/images/Outline_1.svg" loading="lazy" alt="">
                      <div class="time-phone-sms"><span class="bold-time-sms"></span> Время истекло</div>
                    </div>

                  </div>
                </div>
              </div>
              <a
                 v-if="!timerExpire"
                 @click="sendCode"
                 :class="{ 'disable': isCodeSend || disableCode }"
                 class="button-bust w-button"
                 style="margin-top: 20px; width: 100%;">
                Отправить
              </a>
              <a
                  v-else
                  style="margin-top: 20px; width: 100%;"
                  @click="reSendCode"
                  class="button-bust w-button">Повторить код</a>
              <link rel="prerender" @click="sendCode">
            </v-form>
          </form>
          <div v-if="isError" class="form-fail error notification">
            <div>Кажется, код неверный</div>
          </div>

          <div v-if="attempts === 0" class="form-fail error notification">
            <div>Попытки закончились</div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import loadjs from 'loadjs';
import { Footer, HeaderMain } from '/src/app/shared/components';

export default {
  name: 'Login',
  data() {
    return {
      valid_log_check: false,

      timeLifeDefault: Number(process.env.VUE_APP_TIME_EXPIRE_CODE)*60,

      timeLeft: 0,
      startTime: null,
      timer: null,

      attempts: 2,
      maxAttempts: 2,
      isError: false,
      code: '',
      timerExpire: false,
      isCodeSend: false,
      disableCode: false,

      phoneCodeRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^\d{4}$/.test(v) || 'Код должен состоять из 4 цифр'
      ],
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
                console.log('Webflow loaded');
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );

    this.timeLeft = this.timeLifeDefault;
    this.startTime = Date.now();
    this.startTimer();
    this.disableCode = false;
  },
  created() {
  },
  computed: {
    phone() {
      return this.$store.getters.PHONE;
    },
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${String(minutes)
          .padStart(2, '0')}:${String(seconds)
          .padStart(2, '0')}`;
    }
  },
  methods: {
    handleEnter() {
      if (!this.isCodeSend && !this.timerExpire && !this.disableCode) {
        this.sendCode();
      }
    },
    goToUser() {
      this.$router.push('/main');
    },
    goToHome() {
      this.$router.push('/');
    },
    goToReg() {
      this.$router.push('/registration-step1');
    },
    sendCode() {
      if (this.$refs.form_reg_check.validate()) {
        this.isCodeSend = true;

        let payload = {
          payload: {
            phone: this.phone,
            code: this.code
          },
          type: 'authorization'
        };

        this.$store.dispatch('VERIFICATE_CODE_AUTH', payload).then(() => {
          console.log('Код верен!');
          this.$router.push('/main');
        }).catch((message) => {
          console.error('Ошибка кода: ', message);
          // неверный
          this.isError = true;
          this.attempts = this.attempts - 1;
          this.isCodeSend = false;
          if (this.attempts === 0) {
            console.log('Попытки закончились');
            this.isError = false;
            this.disableCode = true;
          }
        });
      } else {
        this.$buefy.toast.open({
          message: "Введите корректный код!",
          type: 'is-danger'
        });
      }
    },
    reSendCode() {
      let payload = {
        phone: this.phone,
        type: 'authorization'
      }
      this.$store.dispatch('NEW_CODE_AUTH', payload).then(() => {
        console.log('Код успешно отправлен');

        this.$buefy.toast.open({
          message: 'Код отправлен',
          type: 'is-success'
        });

        this.attempts = this.maxAttempts;
        this.isCodeSend = false;
        this.isError = false;
        this.timeLeft = this.timeLifeDefault;
        this.startTime = Date.now();
        this.startTimer();
        this.timerExpire = false;
        this.code = '';

      }).catch((message) => {
        console.error('Ошибка отправки кода: ', message);

        this.$buefy.toast.open({
          message: 'Ошибка отправки кода',
          type: 'is-danger'
        });
      });
    },
    errorReset() {
      if (this.isError) {
        this.isError = false;
      }
    },
    startTimer() {
      if (this.timer) {
        cancelAnimationFrame(this.timer);
      }

      const tick = () => {
        const now = Date.now();
        const elapsed = Math.floor((now - this.startTime) / 1000);
        this.timeLeft = this.timeLifeDefault - elapsed;

        if (this.timeLeft > 0) {
          this.timer = requestAnimationFrame(tick);
        } else {
          // Время истекло
          this.timeLeft = 0;
          this.timerExpire = true;
          this.isCodeSend = false;
          this.isError = false;
          this.disableCode = false;
        }
      };

      this.timer = requestAnimationFrame(tick);
    }
  },
  beforeDestroy() {
    if (this.timer) {
      cancelAnimationFrame(this.timer);
    }
  }
};
</script>

<style>
</style>
